import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'
import Form from '../components/form'
import TestimonialCard from '../components/testimonialCard'
import Cf7FormWrapper from '../components/cf7-form-wrapper'

const BlogPage = ({ data }) => {
  return (
    <Layout title="Les témoignages des clients de Jérôme Dicharry, développeur web Gatsby JS | Next JS à Strasbourg">
      <Head
        title="Témoignages sur le travail de développeur web de Jérôme Dicharry à Strasbourg"
        description="Lire les témoignages des clients de Jérôme Dicharry à propos du développement web, du SEO, de php, WordPress, ACF, React JS, Next JS et Gatsby JS"
      />
      <section className="section">
        <div className="mw-800 mb-4">
          <h2 className="mb-2 text-center">
            Les derniers témoignages des clients et agences web qui m'ont fait
            confiance :
          </h2>
          <p>
            Depuis mon démarrage en freelance en janvier 2020, j'ai mis en ligne{' '}
            <strong>plus de 20 sites internets sur mesure</strong> pour des
            agences Web et des clients directs. Ceci inclut des sites vitrines,
            des sites multilingues, des sites e-commerce ainsi qu'une
            application complexe réalisée en React.js.
          </p>
          <p>
            Tous mes sites développés avec Gatsby.js dépassent les 95 sur 100 en
            performances google Lighthouse sur mobile et ordinateur
          </p>
          <p>
            Ce que mes clients apprécient le plus en collaborant avec moi : à
            l'écoute, efficace, impliqué, force de proposition, je produis des
            sites performants, sécurisés, faciles à administrer pour le client,
            avec un design totalement sur mesure. Ci-dessous quelques
            témoignages d'agences web partenaires et de clients directs.
          </p>
        </div>{' '}
      </section>

      <section
        id="testimonial"
        className="bg-dark bubble-background full-width section block--testimonials"
      >
        <div className="grid-container">
          <ul className="block--testimonials__container">
            {data.allContentfulTemoignage.edges.map((edge) => {
              return (
                <TestimonialCard
                  key={edge.node.slug}
                  image={edge.node.image}
                  extrait={edge.node.extrait}
                  content={edge.node.content}
                  author={edge.node.author.author}
                ></TestimonialCard>
              )
            })}
          </ul>
        </div>
      </section>

      <Cf7FormWrapper url="https://jeromedicharry.com/wp-json/contact-form-7/v1/contact-forms/441/feedback">
        <Form />
      </Cf7FormWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulTemoignage {
      edges {
        node {
          slug
          author {
            author
          }
          extrait
          content {
            raw
          }
          image {
            gatsbyImageData(width: 100)
          }
        }
      }
    }
  }
`

export default BlogPage
