import React, { useEffect, useState, useRef, useContext } from 'react'

const Excerpt = ({ text }) => {
  const [addButton, setAddButton] = useState(false)
  const [toggleFullText, setToggleFullText] = useState(false)
  const ref = useRef(null)

  const seeMore = 'Voir +'
  const seeLess = 'Cacher'

  useEffect(() => {
    const maxHeight = 110
    const height = ref.current.clientHeight
    if (height > maxHeight) {
      setAddButton(true)
      setToggleFullText(true)
    }
  }, [])

  return (
    <div
      className={`component--excerpt ${
        addButton && 'component--excerpt--clickable'
      }`}
      onClick={addButton ? () => setToggleFullText(!toggleFullText) : undefined}
    >
      <div>
        <div
          ref={ref}
          className={`to-be-excerpted text-medium ${
            toggleFullText && 'excerpted'
          }`}
        >
          {text}
        </div>
        {addButton && toggleFullText && <div>[...]</div>}
      </div>
      {addButton && (
        <button className="button primary-dark mt-2">
          <span>{toggleFullText ? seeMore : seeLess}</span>
        </button>
      )}
    </div>
  )
}

export default Excerpt
