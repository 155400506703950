import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaQuoteLeft } from 'react-icons/fa'
import Excerpt from './Excerpt'

const TestimonialCard = ({ slug, image, extrait, content, author }) => {
  const testimonialImage = getImage(image)

  return (
    <li className="card--testimonial">
      <article>
        <div className="card--testimonial__image">
          <GatsbyImage
            className="testimonial--image mb-1"
            image={testimonialImage}
            alt={author}
          />
        </div>
        <div className="card--testimonial__content">
          <div className="text-medium text-secondary mb-1">{author}</div>
          <FaQuoteLeft size={22} className="mb-1" />
          {content && <Excerpt text={renderRichText(content)} />}
        </div>
      </article>
    </li>
  )
}

export default TestimonialCard
